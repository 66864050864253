<template>
    <div id="buildingsList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="buildingsAndFloors"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="b&f_buildingsListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link v-if="!isEmptyList" :to="{ name: 'newBuilding' }" id="buildings_createNewBuildingButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("b&f_createNewBuilding") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="buildings" :siteId="siteId"></app-accesserrorportlet>

                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                                                                fill="#000000"
                                                            />
                                                            <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                                                            <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" id="Rectangle-2" fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("b&f_buildingsList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByName')" @keyup="onSearch" v-model="searchedValue" id="buildings_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="BUILDING" mainTextKey="b&f_buildingNotFound" subTextKey="b&f_buildingNotFoundSubLabel" imgName="empty_building.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="buildings_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import AccessErrorPortlet from "./../utils/accesserrorportlet.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteBuildingsTable: null,
            isEmptyList: false,
            isMounted: false,
            searchedValue: ""
        };
    },
    created: function() {
        console.log("Component(siteBuildings)::created() - called");
        if (this.createAnotherBuilding) {
            this.$router.push({ name: "newBuilding" });
        }
    },
    mounted: function() {
        console.log("Component(siteBuildings)::mounted() - Init metronic layout");
        this.isMounted = true;
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(siteBuildings)::destroyed() - called");
        this.resetBuildingsState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteBuildings: function() {
            console.log("Component(siteBuildings)::watch(siteBuildings) called");
            this.initKtDataTable();
        },
        user: function(user) {
            if (user) {
                console.log("Component(siteBuildings)::watch(user) called with : ", user);
                this.initKtDataTable();
            }
        },
        currentSite: function(site) {
            console.log("Component(siteBuildings)::watch(currentSite) called");
            // Load user sites from backend and store the result in sites store.
            this.getSiteBuildingsAndTheirFloors(this.siteId);
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteBuildings", "currentSite", "createAnotherBuilding", "user"]),

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteBuildingsAndTheirFloors", "showDeleteModal", "resetBuildingsState"]),
        onSearch() {
            if (this.siteBuildingsTable) {
                this.siteBuildingsTable.search(this.searchedValue, "name");
            }
        },
        onEditBuilding: function(buildingId) {
            this.$router.push({ name: "editBuilding", params: { buildingId: buildingId, siteId: this.siteId } });
        },
        onOpenFloors: function(buildingId) {
            this.$router.push({ name: "floors", params: { buildingId: buildingId, siteId: this.siteId } });
        },
        registerEventsOnSiteBuildingsTable() {
            var self = this;
            $("#buildings_datatable").on("kt-datatable--on-layout-updated", () => {
                $(".deleteBuildingButton")
                    .off()
                    .on("click", function() {
                        let buildingId = $(this).attr("data-buildingid");
                        let buildingName = $(this).attr("data-buildingname");
                        console.log("Component(SiteBuildings)::registerEventsOnBuildingsTable(deleteBuildingButton) clicked. BuildingId = " + buildingId);
                        self.showDeleteModal({ textContent: i18n.t("b&f_deleteBuildingConfirmText", { buildingName: buildingName }), actionName: "DELETE_BUILDING", params: { siteId: self.siteId, buildingId: buildingId } });
                    });
                $(".editBuildingButton")
                    .off()
                    .on("click", function() {
                        let buildingId = $(this).attr("data-buildingid");
                        console.log("Component(SiteBuildings)::registerEventsOnBuildingsTable(editBuildingButton) clicked. BuildingId = " + buildingId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditBuilding(buildingId);
                    });
                $(".openFloorsButton")
                    .off()
                    .on("click", function() {
                        let buildingId = $(this).attr("data-buildingid");
                        console.log("Component(SiteBuildings)::registerEventsOnBuildingsTable(editBuildingButton) clicked. BuildingId = " + buildingId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to floors vue
                        self.onOpenFloors(buildingId);
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },
        initKtDataTable() {
            var self = this;

            if (this.siteBuildings != null) {

                // If site table has been already build, destroy the table before creating a fresh instance !
                if (this.siteBuildingsTable) {
                    $("#buildings_datatable").KTDatatable().destroy();
                }

                // According to source list length, show datatable or empty list icon+text
                if (this.siteBuildings.length > 0) {
                    // There is data => Hide empty list icon+text
                    this.isEmptyList = false;

                    const options = {
                        data: {
                            type: "local",
                            source: commonVueHelper.preventHtmlInjection(this.siteBuildings),
                            pageSize: 5,
                            saveState: {
                                webstorage: false
                            }
                        },
                        layout: commonVueHelper.getLayoutOptions(),
                        translate: commonVueHelper.getTranslateOptions(),
                        toolbar: commonVueHelper.getToolbarOptions(),
                        sortable: !0,
                        pagination: !0,
                        columns: [
                            {
                                field: "name",
                                title: i18n.t("b&f_buildingName"),
                                textAlign: "center",
                                template: function(row) {
                                    return '\
                                        <div class="kt-user-card-v2">\
                                            <div class="kt-user-card-v2__details openResourceLink">\
                                                <a class="openFloorsButton kt-link kt-user-card-v2__name" data-buildingid="' + row.id + '" href="javascript:;">' + row.name + "</a>\
                                            </div>\
                                        </div>\
                                    ";
                                }
                            },
                            {
                                field: "updatedAt",
                                title: i18n.t("common_lastUpdateDate"),
                                textAlign: "center",
                                sortable: "desc",
                                template: function(row) {
                                    if (row.updatedAt) {
                                        return moment(row.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                                    }
                                }
                            },
                            {
                                field: "floors",
                                title: i18n.t("b&f_numberOfFloors"),
                                textAlign: "center",
                                template: function(row) {
                                    return String(row.floors.length);
                                }
                            },
                            {
                                field: "Actions",
                                title: i18n.t("common_actions"),
                                sortable: false,
                                width: 110,
                                overflow: "visible",
                                autoHide: false,
                                textAlign: "center",
                                template: function(row) {
                                    if (self.currentSite.isInProduction) {
                                        return (
                                            `
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                                i18n.t("common_edit") +
                                                `" type="button" data-buildingid="` +
                                                row.id +
                                                `" class="btn btn-outline-brand btn-sm btn-icon editBuildingButton">
                                                    <i class="la la-pencil"></i>
                                                </button>
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                                i18n.t("b&f_floors") +
                                                `" type="button" data-buildingid="` +
                                                row.id +
                                                `" class="btn btn-outline-brand btn-sm btn-icon openFloorsButton">
                                                    <i class="flaticon-layers"></i>
                                                </button>
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                                i18n.t("common_unauthorizedAction") +
                                                `" type="button" data-buildingid="` +
                                                row.id +
                                                `" data-buildingname="` +
                                                row.name +
                                                `" class="btn btn-outline-danger btn-sm btn-icon disabled">
                                                    <i class="la la-trash"></i>
                                                </button>
                                            `
                                        );
                                    } else {
                                        return (
                                            `
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                                i18n.t("common_edit") +
                                                `" type="button" data-buildingid="` +
                                                row.id +
                                                `" class="btn btn-outline-brand btn-sm btn-icon editBuildingButton">
                                                    <i class="la la-pencil"></i>
                                                </button>
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                                i18n.t("b&f_floors") +
                                                `" type="button" data-buildingid="` +
                                                row.id +
                                                `" class="btn btn-outline-brand btn-sm btn-icon openFloorsButton">
                                                    <i class="flaticon-layers"></i>
                                                </button>
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                                i18n.t("common_delete") +
                                                `" type="button" data-buildingid="` +
                                                row.id +
                                                `" data-buildingname="` +
                                                row.name +
                                                `" class="btn btn-outline-danger btn-sm btn-icon deleteBuildingButton">
                                                    <i class="la la-trash"></i>
                                                </button>
                                            `
                                        );
                                    }
                                }
                            }
                        ]
                    };

                    // Now, we are ready to build the table
                    this.siteBuildingsTable = $("#buildings_datatable").KTDatatable(options);
                    // Once table is build, register click events on buttons inside the table
                    this.registerEventsOnSiteBuildingsTable();
                } else {
                    // There is no data => Show empty list icon+text
                    // No table process: Comment lines below because table destroy previously
                    this.isEmptyList = true;
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
